import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { WindowRefService } from '../../shared/services/window-ref.service';
import { ScriptLoaderService } from '../../shared/analytics/script-loader.service';
import { ConfigurationService } from '../../shared/analytics/configuration.service';
import { ScriptDetail } from '../../shared/analytics/models/script-detail';
import { AnalyticsEventData } from '../../shared/analytics/models/analytics-event-data';
import { DataService } from '../../shared/services/data.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  window: any;
  getUserProfileDataSubscription: any;
  userProfileURL: string;
  private headers: HttpHeaders;
  utagLoaded: boolean;
  analyticsReferringUrl: string;
  envConfigURL: string;
  utagFallbackURL: string;
  analyticsEventData: AnalyticsEventData;

  constructor(
    private windowRef: WindowRefService, private scriptLoader: ScriptLoaderService,
    private http: HttpClient, private configurationService: ConfigurationService, private dataService: DataService) {
    this.headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    });
    this.window = windowRef.getWindow();
    this.window.utag_data = {};
    this.utagFallbackURL = configurationService.ANALYTICS_FALLBACK_UTAG_URL;
  }

  setCommonAnalyticsData(version, page_l3, page_l4) {
    const currObj = this;
    if (!currObj.utagLoaded) {
      return false;
    }
    currObj.setUTagProperty('page_l1', 'LFG');
    currObj.setUTagProperty('page_l2', 'mg eSubmission');
    currObj.setUTagProperty('page_l3', page_l3);
    const contractState = currObj.dataService.getData('contractState');
    if (page_l4 !== '') {
      currObj.setUTagProperty('page_l4', page_l4);
      if (contractState === 'CA') {
        currObj.setUTagProperty('page_l5', 'ca');
      }
    } else {
      if (contractState === 'CA') {
        currObj.setUTagProperty('page_l4', 'ca');
      }
    }
    currObj.setUTagProperty('page_version', version);
    currObj.setUTagProperty('page_lob', 'life');
    currObj.setUTagProperty('product_type', 'life');
    currObj.setUTagProperty('product_name', 'money guard');
    currObj.setUTagProperty('page_name', 'mg eSubmission');
    this.setUserDetails();
    this.setEmptyEventData();
    setTimeout(() => {
      currObj.triggerUTagCollection();
    }, 1500);
  }

  // solution for events data getting reflected in view call at analytics side.
  setEmptyEventData() {
    const currObj = this;
    currObj.setUTagProperty('event_action', '');
    currObj.setUTagProperty('event_name', '');
    currObj.setUTagProperty('event_type', '');
    currObj.setUTagProperty('event_version', '');
    currObj.setUTagProperty('event_status', '');
  }

  setAnalyticsForThankYouComplete(): void {
    const currObj = this;
    currObj.setUTagProperty('event_action', 'self service');
    currObj.setUTagProperty('event_name', 'signed');
    currObj.setUTagProperty('event_type', 'mg eSubmission');
    currObj.setUTagProperty('event_version', 'docusign');
    currObj.setUTagProperty('event_status', 'complete');
  }

  setUserDetails() {
    const currObj = this;
    const guid = currObj.dataService.getData('guid');
    if (guid !== undefined && guid !== null) {
      currObj.setUTagProperty('user_id', guid);
    }
  }

  loadUtagScript(profile: string): void {
    const currObj = this;
    currObj.scriptLoader.loadScript(currObj.getAnalyticsScriptDetail(profile)).subscribe(
      () => {
      currObj.utagLoaded = true;
      console.log('Successfully loaded utag.js.'); },
      () => console.log('Failed to load utag.js.')
    );
  }

  getAnalyticsScriptDetail(profile: string): ScriptDetail {
    const analyticsScriptDetail = new ScriptDetail();
    analyticsScriptDetail.name = 'UTAG';
    analyticsScriptDetail.source = this.getUtagUrl(profile);
    return analyticsScriptDetail;
  }

  getEnvConfigScriptDetail(): ScriptDetail {
    const envConfigScriptDetail = new ScriptDetail();
    envConfigScriptDetail.name = 'ENV_CONFIG';
    envConfigScriptDetail.source = this.envConfigURL;
    return envConfigScriptDetail;
  }

  triggerUTagCollection(): void {
    if (this.window.utag && this.window.utag.data) {
      if (isDevMode()) {
        console.log("triggerUTagCollection in isDevMod");
      }
      const copy = this.extend({}, this.window.utag.data);
      this.window.utag.view(copy);
      console.log('Successfully fired view call');
    } else {
      console.error('Cannot fire analytics view tracking.');
    }
  }

  extend(obj, src): any {
    for (const key in src) {
      if (src.hasOwnProperty(key)) {
        obj[key] = src[key];
      }
    }
    return obj;
  }

  unsetVariables(data: any) {
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        delete this.window.utag.data[property];
      }
    }
  }

  getUtagUrl(profile: string): string {
    const env = this.configurationService.getEnv();
    const profileConfig = this.configurationService.UTAG_ENV_CONFIG[env];
    let utagUrl: string = '';
    console.log('ENV : Set val');
    Object.keys(profileConfig).forEach(function(key, index) {
      if (profile.toLowerCase() === key.toLocaleLowerCase()) {
        utagUrl = profileConfig[key];
      }
    });
    return utagUrl;
  }

  setUTagProperty(propertyName: string, propertyValue: string): any {
    this.window.utag_data[propertyName] = propertyValue;
  }

  link(analyticsData: AnalyticsEventData): void {
    if (isDevMode()) {
      console.log("analyticsData");
    }
    if (this.window.utag && this.window.utag.link) {
      const data = _.merge({}, this.window.utag.data, analyticsData);
      this.window.utag.link(data);
      console.log('Successfully fired link call');
    } else {
      console.error('Cannot fire analytics link tracking.');
    }
  }

  setAnalyticsEventData(eventAction, eventName, eventType, eventVersion, eventStatus) {
    const currObj = this;
    this.analyticsEventData = new AnalyticsEventData();
    this.analyticsEventData.event_action = eventAction;
    this.analyticsEventData.event_name = eventName;
    this.analyticsEventData.event_type = eventType;
    this.analyticsEventData.event_version = eventVersion;
    this.analyticsEventData.event_status = eventStatus;
    currObj.link(this.analyticsEventData);
  }

}
