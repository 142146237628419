import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OtpModalModel } from '../model/otp-modal-model';

@Injectable({
  providedIn: 'root',
})
export class OtpService {
  otpObject = {
    showOtpModal: false,
    isOtpRecieved: false,
    isOtpVerified: false,
    currentModalToOpen: '',
    lineType: '',
    responseMsg: '',
    optRecievingPhoneNumber: '',
    guid: '',
    commonErrMsg: '',
    otpPhnNumRegStatus: false,
    continueLogin: false,
    manualClose: false,
    otpCompSessionTimeOut: false,
    transactionId: '',
    lastFourDigitSsn: '',
    questionsCompletedStatus: '',
    otpRegFlg: false,
    isQstnPageOne: true,
    otpPhnNumInvalid: false
  };
  otpSubject = new BehaviorSubject<OtpModalModel>(<OtpModalModel>(this.otpObject));

  /**
   * To get-set OTP modal properties
   */
  getOtpSubject = this.otpSubject.asObservable();
  setOtpSubject(obj) {
    this.otpObject = {...this.otpObject, ...obj};
    this.otpSubject.next(this.otpObject);
  }

}
