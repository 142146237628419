import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';

import { EntLoaderService } from '@ng/ent-components/ent-loader';

import { AnalyticsService } from './shared/analytics/analytics.service';
import { UtilsService } from './shared/services/utils.service';
import { SubjectService } from './shared/services/subject.service';
import { DataService } from './shared/services/data.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
   constructor(@Inject(DOCUMENT) private document: any, private analyticsService: AnalyticsService, private router: Router,
              private lfgLoaderService: EntLoaderService, private utilsService: UtilsService, private subjectService: SubjectService,
              private dataService: DataService) { }

  navData = {
    supportNav: [],
    login: false,
    search: false,
    menu: [],
    noNav: true,
    headerType: 'logoCenter',
    footerType: 'noLogo',
    lincolnLogoUrl: 'https://www.lfg.com',
    logoImgSrc: 'assets/images/shared/lfg-logo.svg',
    siteWideMsg:{}
  };

  ngOnInit() {
    this.analyticsService.loadUtagScript('LFG_RWD');
    this.lfgLoaderService.show();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = this.router.url;
        if (url && (url.indexOf('login') >= 0 || url.indexOf('consent') >= 0)) {
          this.navData.headerType = 'logoCenter';
          this.document.body.className = 'logoCenter';
          setTimeout(() => {
            this.utilsService.openHeaderFooterLinksInNewTab(this);
            document.links[0]?.focus();
          }, 100);
        } else {
          this.navData.headerType = '';
          this.document.body.className = '';
          setTimeout(() => {
            this.utilsService.openHeaderFooterLinksInNewTab(this);
            document.links[0]?.focus();
          }, 200);
        }
      }
    });
    // maintenance banner changes
    this.subjectService.enableMaintBanner.subscribe(displayFlag => {
      if (displayFlag && !sessionStorage.getItem('SiteWideMsg') && sessionStorage.getItem('SiteWideMsg') !== 'false') {
        const bannerMessage = this.dataService.getData('bannerMessage');
        this.navData.siteWideMsg = {
          EnableMsg: 'Yes',
          AlertMsg: bannerMessage,
          InfoMsg: null
        };
        setTimeout(() => {
          const url = this.router.url;
          if (url && (url.indexOf('login') === -1 && url.indexOf('consent') === -1)) {
            window.document.body.className = 'banner';
          }
          setTimeout(() => {
            const ele:any = window.document.getElementsByClassName('fas fa-exclamation-triangle');
            // tslint:disable-next-line:prefer-for-of
            /* comment due to sonar
            for (let i = 0; i < ele.length; i++) {
              ele[i].classList.add('fa-wrench');
            }
            */
            for (let eachEle of ele) {
              eachEle.classList.add('fa-wrench');
              eachEle.classList.remove('fa-exclamation-triangle');
            }

            // tslint:disable-next-line:prefer-for-of
            /* Merge this logic on above for
            for (let i = 0; i < ele.length; i++) {
              ele[i].classList.remove('fa-exclamation-triangle');
            }*/
          }, 100);
        }, 200);
      } else {
        this.navData.siteWideMsg = {};
      }
    });
    const interval = setInterval(() => {
      if (sessionStorage.getItem('SiteWideMsg') && sessionStorage.getItem('SiteWideMsg') === 'false') {
        window.document.body.className = '';
        clearInterval(interval);
      }
    }, 1000);
  }

  onActivate(): void {
    window.history.pushState(null,window.location.href);
    window.onpopstate = function(){
      window.history.pushState(null,window.location.href);
    };
  }

}
