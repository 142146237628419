import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lfg-header',
  templateUrl: './lfg-header.component.html',
  styleUrls: ['./lfg-header.component.scss'],
})
export class LfgHeaderComponent implements OnInit {
  @Input() navData: any;

  ngOnInit(): void {
    //  document why this method 'ngOnInit' is empty
  }
}
