import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';

import { EntFormWidgetsModule } from '@ng/ent-components/ent-form-widgets';

import { EntModalPopupModule } from '@ng/ent-components/ent-modal-popup';

import { EntButtonTrayModule } from '@ng/ent-components/ent-button-tray';

import { EntCalendarModule } from '@ng/ent-components/ent-calendar';
import { TextMaskModule } from 'angular2-text-mask';

import { AnimationHelpComponent } from './components/animation-help/animation-help.component';
import { DocusignOverlayComponent } from './components/docusign-overlay/docusign-overlay.component';
import { HelpmodalComponent } from './components/helpmodal/helpmodal.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TransitionOverlayComponent } from './components/transition-overlay/transition-overlay.component';

import { MakeUntouchedOnBlurDirective } from './directives/makeUntouchOnBlur/make-untouched-on-blur.directive';
import { AppOnlyNumberDirective } from './directives/onlyNumber/only-number.directive';
import { AppStopPasteDirective } from './directives/noCopyPaste/no-copypaste.directive';
import { NoSpecialSymbolsDirective } from './directives/noSpecialSymbol/no-special-symbols.directive';
import { ProgBtnDirective } from './directives/prog-btn/prog-btn.directive';
import { DateOfBirthFormatterDirective } from './directives/dateOfBirthFormatter/date-of-birth-formatter.directive';
import { ValidateOnBlurDirective } from './directives/appValidateOnBlur/validate-on-blur.directive';
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
import { OtpModalComponent } from './components/otp/otp-modal.component';
import { PhoneNumRegistrationComponent } from './components/otp/phone-num-registration/phone-num-registration.component';
import { OtpVerificationComponent } from './components/otp/otp-verification/otp-verification.component';
import { OtpRecievingDeviceComponent } from './components/otp/otp-recieving-device/otp-recieving-device.component';
import { OtpCommonFormComponent } from './components/otp/otp-common-form/otp-common-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LfgHeaderComponent } from './components/header-footer/lfg-header/lfg-header.component';
import { LfgFooterComponent } from './components/header-footer/lfg-footer/lfg-footer.component';
import { EntUtilitiesModule } from '@ng/ent-utilities';

@NgModule({
  imports: [
    CommonModule,
    EntFormWidgetsModule,
    EntModalPopupModule,
    EntButtonTrayModule,
    TextMaskModule,
    EntCalendarModule,
    NgxMaskModule.forRoot(),
    FormsModule ,
    ReactiveFormsModule,
    HttpClientModule,
    EntUtilitiesModule
  ],
  declarations: [
    AnimationHelpComponent,
    DocusignOverlayComponent,
    HelpmodalComponent,
    ProgressBarComponent,
    TransitionOverlayComponent,
    MakeUntouchedOnBlurDirective,
    AppOnlyNumberDirective,
    AppStopPasteDirective,
    NoSpecialSymbolsDirective,
    ProgBtnDirective,
    DateOfBirthFormatterDirective,
    ValidateOnBlurDirective,
    MaintenancePageComponent,
    OtpModalComponent,
    OtpCommonFormComponent,
    PhoneNumRegistrationComponent,
    OtpVerificationComponent,
    OtpRecievingDeviceComponent,
    LfgHeaderComponent,
    LfgFooterComponent
  ],
  exports: [
    CommonModule,
    EntFormWidgetsModule,
    EntModalPopupModule,
    EntButtonTrayModule,
    EntCalendarModule,
    NgxMaskModule,    // require as dependency for lfg-calendar
    TextMaskModule,
    AnimationHelpComponent,
    DocusignOverlayComponent,
    HelpmodalComponent,
    ProgressBarComponent,
    TransitionOverlayComponent,
    MakeUntouchedOnBlurDirective,
    AppOnlyNumberDirective,
    AppStopPasteDirective,
    NoSpecialSymbolsDirective,
    ProgBtnDirective,
    DateOfBirthFormatterDirective,
    ValidateOnBlurDirective,
    OtpModalComponent,
    OtpCommonFormComponent,
    PhoneNumRegistrationComponent,
    OtpVerificationComponent,
    OtpRecievingDeviceComponent,
    HttpClientModule,
    LfgHeaderComponent,
    LfgFooterComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
