import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class SubjectService {

  showHelp: Subject<boolean> = new Subject();
  private showHelpValue = false;

  docusignUrlToRedirect: Subject<boolean> = new Subject();
  private docusignUrlToRedirectValue = false;

  showDocusign: Subject<boolean> = new Subject();
  private showDocusignValue = false;

  displayReviewPage: Subject<boolean> = new Subject();
  private displayReviewPageValue = false;

  checkBox: Subject<boolean> = new Subject();
  private checkBoxValue = false;

  checkLogout: Subject<boolean> = new Subject();
  private checkLogoutValue = false;

  listOfQuestionsAnsweredChanged: Subject<boolean> = new Subject();
  private listOfQuestionsAnsweredChangedValue = false;

  medicationGridUpdate: Subject<boolean> = new Subject();
  private medicationGridUpdateValue = false;

  docusignStatusCode: Subject<string> = new Subject();
  private docusignStatusCodeValue = '';

  ltcAisTransitionModal: Subject<boolean> = new Subject();
  private ltcAisTransitionModalValue = false;

  isAISLoaded: Subject<boolean> = new Subject();
  private isAISLoadedValue = false;

  enableMaintBanner: Subject<boolean> = new Subject();
  private enableMaintBannerVal = false;

  showTopLevelErr: Subject<boolean[]> = new Subject();
  private showTopLevelErrVal = [false, false, false];

  displayCompletePage: Subject<boolean> = new Subject();
  private displayCompletePageValue = false;

  answerChanged: Subject<boolean> = new Subject();
  private answerChangedValue = false;

  setShowHelpVal(value: boolean) {
    this.showHelp.next(value);
  }
  getShowHelpVal(): boolean {
    return this.showHelpValue;
  }

  setDisplayReviewPage(value: boolean) {
    this.displayReviewPage.next(value);
  }
  getDisplayReviewPage(): boolean {
    return this.displayReviewPageValue;
  }

  setCheckBoxValue(value: boolean) {
    this.checkBox.next(value);
  }
  getCheckBoxValue(): boolean {
    return this.checkBoxValue;
  }

  setDocusignValue(value: boolean) {
    this.showDocusign.next(value);
  }
  getDocusignValue(): boolean {
    return this.showDocusignValue;
  }

  setDocusignUrlToRedirectValue(value: boolean) {
    this.docusignUrlToRedirect.next(value);
  }
  getDocusignUrlToRedirectValue(): boolean {
    return this.docusignUrlToRedirectValue;
  }

  setCheckLogoutValue(value: boolean) {
    this.checkLogout.next(value);
  }
  getCheckLogoutValue(): boolean {
    return this.checkLogoutValue;
  }

  setListOfQuestionsAnsweredChangedValue(value: boolean) {
    this.listOfQuestionsAnsweredChanged.next(value);
  }
  getListOfQuestionsAnsweredChangedValue(): boolean {
    return this.listOfQuestionsAnsweredChangedValue;
  }

  setMedicationGridUpdateValue(value: boolean) {
    this.medicationGridUpdate.next(value);
  }
  getMedicationGridUpdateValue(): boolean {
    return this.medicationGridUpdateValue;
  }

  setDocusignStatusCodeValue(value: string) {
    this.docusignStatusCode.next(value);
  }
  getDocusignStatusCodeValue(): string {
    return this.docusignStatusCodeValue;
  }

  setLtcAisTransitionModalValue(value: boolean) {
    this.ltcAisTransitionModal.next(value);
  }
  getLtcAisTransitionModalValue(): boolean {
    return this.ltcAisTransitionModalValue;
  }

  setIsAISLoadedValue(value: boolean) {
    this.isAISLoaded.next(value);
  }
  getIsAISLoadedValue(): boolean {
    return this.isAISLoadedValue;
  }

  setEnableMaintBanner(value: boolean) {
    this.enableMaintBanner.next(value);
  }
  getEnableMaintBanner(): boolean {
    return this.enableMaintBannerVal;
  }

  setShowTopLevelErr(value: boolean[]) {
    this.showTopLevelErr.next(value);
  }
  getShowTopLevelErr(): boolean[] {
    return this.showTopLevelErrVal;
  }

  setDisplayCompletePage(value: boolean) {
    this.displayCompletePage.next(value);
  }
  getDisplayCompletePage(): boolean {
    return this.displayCompletePageValue;
  }

  setAnswerChanged(value:boolean) {
    this.answerChanged.next(value);
  }
  getAnswerChanged(): boolean {
    return this.answerChangedValue;
  }

}
