<app-lfg-header [navData]="navData"></app-lfg-header>

<main role="main" class="no-nav">
  <div class="u-global-container">
    <!-- Content 100% -->
    <section class="content-wrapper">
      <article class="main-content" data-event-type="mclink">
        <section class="content-area">
          <!-- Content -->
          <label for="g-recaptcha-response-100000" class="visuallyhidden">aria-hidden=true</label>
          <router-outlet (activate)="onActivate()"></router-outlet>
        </section>
      </article>
    </section>
  </div>
</main>

<ent-loader></ent-loader>
<app-session-timeout></app-session-timeout>
<app-lfg-footer [navData]="navData"></app-lfg-footer>
