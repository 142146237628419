import { Injectable } from '@angular/core';
import { Observable ,Observer } from 'rxjs';
import * as _ from 'lodash';
import { ScriptDetail } from '../../shared/analytics/models/script-detail';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {

  private scriptDetails: Array<ScriptDetail>;

  constructor() {
    this.scriptDetails = new Array<ScriptDetail>();
  }

  loadScript(scriptDetail: ScriptDetail): Observable<ScriptDetail> {
    const currObj = this;
    const scriptLoader = new Observable<ScriptDetail>((observer) => currObj.loadScriptObserver(observer, scriptDetail));

    return scriptLoader;
  }

  private loadScriptObserver(observer: Observer<ScriptDetail>, scriptDetail: ScriptDetail): void {
    const currObj = this;
    const existingScript: ScriptDetail = _.find(currObj.scriptDetails, scriptDetail.name);

    if (existingScript && existingScript.name) {
      observer.next(existingScript);
      observer.complete();
    } else {
      currObj.newScript(observer, scriptDetail);
    }
  }

  private newScript(observer: Observer<ScriptDetail>, scriptDetail: ScriptDetail): void {
    const currObj = this;
    currObj.scriptDetails.push(scriptDetail);
    const scriptElement = document.createElement('script');
    scriptElement.src = scriptDetail.source;
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;

    scriptElement.onload = () => {
      scriptDetail.isLoaded = true;
      observer.next(scriptDetail);
      observer.complete();
    };

    scriptElement.onerror = () => {
      scriptDetail.isLoaded = false;
      observer.error(scriptDetail);
      observer.complete();
    };

    document.getElementsByTagName('body')[0].appendChild(scriptElement);
  }
}
