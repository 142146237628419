import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {

  PUBLIC_CHAT_SERVICE_URL = '/pbl-gateway-service-app/servlet/pbl-gateway/gatewayServlet ';
  ANALYTICS_FALLBACK_UTAG_URL = '//tags.tiqcdn.com/utag/lfg/lfgrwd/dev/utag.js';
  STEP1_LOAD_COUNT = 0;
  APP_LOAD_COUNT   = 0;

  UTAG_ENV_CONFIG = {
    LOCAL: {
      LFG_RWD: '//tags.tiqcdn.com/utag/lfg/lfgrwd/dev/utag.js'
    },
    DEV: {
      LFG_RWD: '//tags.tiqcdn.com/utag/lfg/lfgrwd/dev/utag.js'
    },
    TEST: {
      LFG_RWD: '//tags.tiqcdn.com/utag/lfg/lfgrwd/dev/utag.js'
    },
    UAT: {
      LFG_RWD: '//tags.tiqcdn.com/utag/lfg/lfgrwd/dev/utag.js'
    },
    PREPROD: {
      LFG_RWD: '//tags.tiqcdn.com/utag/lfg/lfgrwd/dev/utag.js'
    },
    PROD: {
      LFG_RWD: '//tags.tiqcdn.com/utag/lfg/lfgrwd/prod/utag.js'
    }
  };

  constructor() {
    this.PUBLIC_CHAT_SERVICE_URL = this.getURL(this.PUBLIC_CHAT_SERVICE_URL);
  }

  getURL(url) {
    const currentDomain = window.location.host;
    const protocol = window.location.protocol;
    return (protocol + '//' + currentDomain + url);
  }

  getEnv() {
    const currentDomain = window.location.host;
    if (_.includes(currentDomain, 'dev')) {
      return 'DEV';
    } else if (_.includes(currentDomain, 'test')) {
      return 'TEST';
    } else if (_.includes(currentDomain, 'ca')) {
      return 'UAT';
    } else if (_.includes(currentDomain, 'preprod')) {
      return 'PREPROD';
    } else if (_.includes(currentDomain, 'www.lfg.com')) {
      return 'PROD';
    } else {
      return 'LOCAL';
    }
  }
}
