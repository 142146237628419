export class ChatFormModel {
  SERVICE_ID: string;
  appId: string;
  pageId: string;
  actvtySrcName: string;
  lob: string;
  firstName: string;
  lastName: string;
  emailId: string;
  policyNumber: string;
  email: string;
  WSUID?:string;
}
