<footer role="contentinfo" class="footer-overrides">
    <div class="u-global-container u-global-container-stretch">
      <section class="footer-copy">
        <section class="footer-extras footer-extras--color">
          <div class="footer-legal">
            <section class="footer-legal__copy">
              <nav class="footer-legal-nav">
                <ul class="footer-legal-nav-menu">
                  <li class="footer-legal-nav-menu__item">
                    <a class="footer-legal-nav-menu__link" href="https://www.lfg.com/privacy" target="_blank" rel="noopener">PRIVACY</a>
                  </li>
                  <li class="footer-legal-nav-menu__item">
                    <a class="footer-legal-nav-menu__link" href="https://www.lfg.com/legal" target="_blank" rel="noopener">LEGAL</a>
                  </li>
                  <li class="footer-legal-nav-menu__item">
                    &copy; {{currentYear}} Lincoln National Corporation. All rights reserved.
                  </li>
                </ul>
              </nav>
            </section>
            <section class="footer-legal__disclaimer">
              Lincoln Financial Group is the marketing name for Lincoln National Corporation and insurance company affiliates, including The Lincoln National Life Insurance Company,
              Fort Wayne, IN, and in New York, Lincoln Life &amp; Annuity Company of New York, Syracuse, NY.
              Variable products distributed by broker-dealer/affiliate Lincoln Financial Distributors, Inc., Radnor, PA. Securities and investment advisory services offered through 
              other affiliates. The Lincoln National Life Insurance Company does not solicit business in the state of New York, nor is it authorized to do so. Not available in NY.
            </section>
          </div>
        </section>
      </section>
    </div>
  </footer>
  