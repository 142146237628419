import { Injectable } from '@angular/core';

@Injectable()
export class SessionTimeoutService {

  constructor() { 
    //empty constructor
  }

  public millisToMinutes(millis) {
    if ( !isNaN( millis ) ) {
      // tslint:disable-next-line:radix
      return parseInt( millis ) / 60000;
    } else {
     return 0;
    }
  }

  public minToMillisec( min ) {
    if ( !isNaN( min ) ) {
      // tslint:disable-next-line:radix
      return parseInt( min ) * 60000;
    } else {
      return 0;
    }
  }

}
