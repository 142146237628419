import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {

    public data: Array<any>;
    sessionKeeperDefault: any = {};

    constructor() {
        //empty constructor
    }

    public setData(key, value) {
        this.sessionKeeperDefault[key] = value;
    }

    public getData(key) {
        return this.sessionKeeperDefault[key];
    }

}
