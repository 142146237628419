export class AnalyticsEventData {
    event_action: string;
    event_name: string;
    event_type: string;
    event_search_results: string;
    event_search_terms: string;
    event_status: string;
    event_version: string;
    event_position: string;
    event_product: string;
    form_result: string;
}
