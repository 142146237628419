import { Component, OnInit, NgZone, HostListener, isDevMode } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription , timer } from 'rxjs';

import { EntRestClientService } from '@ng/ent-utilities';


import { WindowRefService } from '../../shared/services/window-ref.service';
import { SessionTimeoutService } from './session-timeout.service';
import { environment } from '../../../environments/environment';
import { DataService } from '../../shared/services/data.service';
import { SubjectService } from '../../shared/services/subject.service';
import { OtpService } from 'app/shared/services/otp.service';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html'
})
export class SessionTimeoutComponent implements OnInit {

  private window: any;
  private clickTimestamp: Date;
  private keepAliveSubscription: Subscription;
  private sessionTimeoutTimerId: any;
  private prepareSessionTimeoutTimerId: any;
  private showSessionTimeoutModal: boolean;
  private resetError: boolean;

  constructor(
    private windowRef: WindowRefService,
    private timeoutService: SessionTimeoutService,
    private clientService: EntRestClientService,
    private ngZone: NgZone,
    private router: Router,
    private dataService: DataService,
    private _subjectService: SubjectService,
    private route: ActivatedRoute,
    private _otpService: OtpService
  ) {
      this.clickTimestamp = new Date();
      this.window = windowRef.getWindow();
    }

  ngOnInit() {

    const currObj = this;
    currObj.resetError = false;
    currObj.window.anf = currObj.window.anf || {};
    currObj.window.anf.sessionManager = currObj.window.anf.sessionManager || {};
    currObj.window.anf.sessionManager.keepChildSessionAlive = this.keepChildSessionAlive.bind(this);
    currObj.window.anf.sessionManager.killChildSession = this.killChildSession.bind(this);
    currObj.initializePing();

  }

  initializePing(): void {
    const currObj = this;
    const keepAliveObservable = timer(0,
      this.timeoutService.minToMillisec(environment.sessionTimeout.KEEP_ALIVE_REQUEST_THRESHOLD_IN_MIN));

    currObj.keepAliveSubscription = keepAliveObservable.subscribe(t => currObj.keepAlive(t), error => this.logout());
  }

  keepAlive(t?: number): boolean {
    const currObj = this;
    const elapsedTime = currObj.getElapsedTime();
    if (isDevMode()) {
      console.log('Timer: ', t);
      console.log('click timestamp', currObj.clickTimestamp);
      console.log('elapsed time since last click ', elapsedTime);
    }
    if (elapsedTime < environment.sessionTimeout.KEEP_ALIVE_REQUEST_THRESHOLD_IN_MIN) {
      currObj.keepSessionAlive(true);
      return true;
    } else {
      return false;
    }
  }

  getElapsedTime(): number {
    const currObj = this;
    return currObj.timeoutService.millisToMinutes(new Date().getTime() - currObj.clickTimestamp.getTime());
  }

  keepSessionAlive(parent?: boolean): void {
    const currObj = this;
    currObj.clientService.get(environment.serviceUrls.keepAlive, 'text').subscribe(success => {
      if (parent) {
        currObj.keepParentSessionAlive();
      }
      currObj.resetTimer();
    },
      error => console.log('Error while keep alive session call'));
  }

  keepChildSessionAlive(): void {
    console.log('Keep child session alive.');
    const currObj = this;
    this.ngZone.run(() => {
      currObj.clickTimestamp = new Date();
      currObj.keepSessionAlive(false);
    });
  }

  killChildSession(): void {
    console.log('Kill child session alive.');
    const currObj = this;
    this.ngZone.run(() => {
      currObj.performSessionTimeout(false);
    });
  }

  keepParentSessionAlive(): void {
    console.log('Keep parent session alive.');
    const currObj = this;
    if (currObj.window && currObj.window.opener && currObj.window.opener.keepParentSessionAlive) {
      currObj.window.opener.keepSessionAlive();
    } else {
      console.warn('Parent window not found.');
    }
  }

  killParentSession(): void {
    console.log('Kill parent session.');
    const currObj = this;
    if (currObj.window && currObj.window.opener && currObj.window.opener.killSession) {
      currObj.window.opener.killSession();
    } else {
      console.warn('Parent window not found.');
    }
  }

  logout(): void {
    console.log('userlogged out');
    this._subjectService.setCheckLogoutValue(true);
    this.router.navigate(['../lifeclient/login'], {relativeTo: this.route});
    this.dataService.setData('sessionTimeOut', true);
    this._otpService.setOtpSubject({otpCompSessionTimeOut: true});
  }

  startTimer(): void {
    const currObj = this;
    currObj.sessionTimeoutTimerId = setTimeout(() => {
      currObj.prepareSessionTimeout(true);
    }, currObj.timeoutService.minToMillisec(environment.sessionTimeout.DURATION_IN_MIN));
  }

  resetTimer(): void {
    const currObj = this;
    clearTimeout(currObj.sessionTimeoutTimerId);
    currObj.startTimer();
  }

  prepareSessionTimeout(parent?: boolean): void {
    const currObj = this;
    if (currObj.keepAlive()) {
      return;
    }
    currObj.keepSessionAlive(parent);
    currObj.keepAliveSubscription.unsubscribe();
    currObj.prepareSessionTimeoutTimerId = setTimeout(() => {
      currObj.performSessionTimeout(parent);
    }, currObj.timeoutService.minToMillisec(environment.sessionTimeout.SHOW_ALERT_BEFORE_IN_MIN));
  }

  performSessionTimeout(parent?: boolean): void {
    const currObj = this;
    if (parent) {
      currObj.killParentSession();
    }
    currObj.logout();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.clickTimestamp = new Date();
  }

  reset() {
    this.clickTimestamp = new Date();
    this.keepSessionAlive(true);
    this.initializePing();
    clearTimeout(this.prepareSessionTimeoutTimerId);
  }
}
