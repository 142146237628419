import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lfg-footer',
  templateUrl: './lfg-footer.component.html',
  styleUrls: ['./lfg-footer.component.scss'],
})
export class LfgFooterComponent implements OnInit {
  @Input() navData: any;

  currentYear = new Date().getFullYear();

  constructor() {
    // constructor for footer component
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
  }
}
