export const AppConstants = {

// service ID
SER_ID_LOGIN_AUTHORIZATION: 'AWS_LIFE_CLIENT_SERVICE',
SER_ID_OPT_OUT: 'AWS_LIFE_CLIENT_SERVICE_OPTOUT',
SER_ID_UPDATE_CLIENT_INFO: 'AWS_LIFE_CLIENT_SERVICE_UPDATE_INFO',
SER_ID_GET_LTC_QUESTIONS: 'AWS_LIFE_CLIENT_SERVICE_GET_QUESTIONS',
SER_ID_SAVE_LTC_ANSWERS: 'AWS_LIFE_CLIENT_SERVICE_SAVE_LTC_ANSWERS',
SER_ID_SAVE_MEDICAL_ANSWERS: 'AWS_LIFE_CLIENT_SERVICE_SAVE_MEDICAL_ANSWERS',
SER_ID_VALIDATE_GUID: 'AWS_LIFE_CLIENT_SERVICE_LOGIN_URL_STATUS',
SER_ID_SUBMIT_ANSWERS_TO_NBIT: 'AWS_LIFE_CLIENT_SERVICE_SUBMIT_TO_NBIT',
SER_ID_GET_DOCUSIGN_URL : 'AWS_LIFE_CLIENT_SERVICE_GET_DOCUSIGN_URL',
SER_ID_GET_DOCUSIGN_URL_ON_RELOGIN : 'AWS_LIFE_CLIENT_SERVICE_GET_DOCUSIGN_URL_ON_LOGIN',

// default landing page
LTC_DOCUSIGN_PAGE: 'LTC Docusign page',
MED_DOCUSIGN_PAGE: 'Medical Docusign page',
WELCOME_PAGE: 'Welcome Page',
LTC_PAGE_1: 'LTC page1',
LTC_PAGE_2: 'LTC page2',
LTC_PAGE_3: 'LTC page3',
LTC_PAGE_4: 'LTC page4',
MEDICAL_PAGE_1: 'MedInfo page1',
MEDICAL_PAGE_2: 'MedInfo page2',
MEDICAL_PAGE_3: 'MedInfo page3',
MEDICAL_PAGE_4: 'MedInfo page4',
MEDICAL_PAGE_5: 'MedInfo page5',
MEDICAL_PAGE_6: 'MedInfo page6',
MEDICAL_PART_III_PAGE_1: 'MedInfo partIII page1',
MEDICAL_PART_III_PAGE_2: 'MedInfo partIII page2',
MEDICAL_PART_III_PAGE_3: 'MedInfo partIII page3',
MEDICAL_PART_III_PAGE_4: 'MedInfo partIII page4',
MEDICAL_PART_III_PAGE_5: 'MedInfo partIII page5',

// routing for pages
ROUTE_TO_LOGIN: 'login',
ROUTE_TO_WELCOME: 'welcome',
ROUTE_TO_LTC: 'ltc',
ROUTE_TO_MEDICAL_INFO: 'medicalpart2',
ROUTE_TO_MEDICAL_INFO_PART_III: 'medicalpart3',

// disclosure
DISCLOSURE_TITLE: 'Disclosure',
// tslint:disable-next-line:max-line-length
DISCLOSURE_TEXT: 'Securities distributed by Lincoln Financial Distributors, Inc., a broker-dealer and marketing organization of Lincoln Financial Group. Insurance offered through Lincoln affiliates. Lincoln Financial Group is the marketing name for Lincoln National Corporation and its affiliates. Affiliates are separately responsible for their own financial and contractual obligations. Insurance products are issued by The Lincoln National Life Insurance Company, Fort Wayne, IN, and, in New York, Lincoln Life & Annuity Company of New York, Syracuse, NY.',
LCN_NUMBER: 'LCN 3307314-102820',

// error messages
INVALID_INPUT_CREDENTIALS: 'We are unable to verify the information entered. Please try again.',
LOGIN_RETRY_EXCEEDED: 'We are unable to verify the information entered. Please contact 844-883-8342.',
// tslint:disable-next-line:max-line-length
SYSTEM_ERROR: 'The application is currently unavailable at this time. We are working to resolve the issue and apologize for any inconvenience this may cause. Please try again at a later time.',
DECLINED_MSG: 'Thank you for your time. You will be contacted to schedule a phone interview in order to complete your application.',
UPDATE_INFO_SUCCESS_MSG: 'Your advisor has been notified. Please contact your advisor with any additional questions.',
DATA_LOST_WARNING_MSG: 'Are you sure you want to cancel? All data will be lost.',
DECLINE_CONFIRMATION_MSG: 'Are you sure you want to decline electronic transmissions? Access to this application will be closed.',
LTC_QUE_NOT_COMP_WRNG_MSG: 'You have unanswered questions. All questions are required before proceeding with the application.',
// tslint:disable-next-line:max-line-length
SESSION_TIMEOUT_MSG: 'Your session has expired. To resume your application, please &nbsp;&nbsp;&nbsp; re-enter the following information so we can locate your information.',
// tslint:disable-next-line:max-line-length
SESSION_TIMEOUT_MSG_MOB: 'Your session has expired. To resume your application, please re-enter the following information so we can locate your information.',
// tslint:disable-next-line:max-line-length
INVALID_GUID_MSG: 'Your application must be accessed from the link in the email that was sent to you. If you no longer have the e-mail, please contact &emsp;&emsp;&emsp; 844-883-8342.',
// tslint:disable-next-line:max-line-length
INVALID_GUID_MSG_MOB: 'Your application must be accessed from the link in the email that was sent to you. If you no longer have the e-mail, please contact 844-883-8342.',
INVALID_EMAIL_URL: 'We are sorry but access to your application has expired.',
INVALID_OPT_OUT: 'Your application is closed as our records indicate that you have declined to electronically submit your application.',
INVALID_APPLICATION_MSG: 'Our records indicate you have already completed your application. Thank you for your submission.',
USER_LOCKED_MSG: 'We are unable to verify the information entered. Please contact your Advisor.',
// tslint:disable-next-line:max-line-length
USER_LOCKED_MSG_EAPP: 'There have been too many unsuccessful attempts to validate your information. Access to this application has been restricted. Please contact your Financial Advisor for assistance.',
// tslint:disable-next-line:max-line-length
MED_PART_II_QUE_NOT_COMP_WRNG_MSG: 'You have unanswered questions. Please review and provide any additional information, or select the &#39;confirmation&#39; checkbox below to proceed.',

// thank you page redirect
REDIRECT_TO_LINCOLN_SITE: 'https://www.lfg.com',

// LTC questions config
LTC_PG1_LOWER_LIMIT: 0,
LTC_PG1_UPPER_LIMIT: 1,
LTC_PG2_LOWER_LIMIT: 1,
LTC_PG2_UPPER_LIMIT: 6,
LTC_PG3_LOWER_LIMIT: 6,
LTC_PG3_UPPER_LIMIT: 14,
LTC_AU_PG3_UPPER_LIMIT: 13,

// MG24 LTC questions config
MG24_LTC_PG1_LOWER_LIMIT: 0,
MG24_LTC_PG1_UPPER_LIMIT: 1,
MG24_LTC_PG2_LOWER_LIMIT: 1,
MG24_LTC_PG2_UPPER_LIMIT: 6,
MG24_LTC_PG3_LOWER_LIMIT: 6,
MG24_LTC_PG3_UPPER_LIMIT: 16,
MG24_LTC_AU_PG3_UPPER_LIMIT: 16,
MG24_LTC_AU_PG3_UPPER_LIMIT_NJ: 17,

// Medical questions config for mgIII non CA
MEDICAL_MGIII_PG1_LOWER_LIMIT: 0,
MEDICAL_MGIII_PG1_UPPER_LIMIT: 2,
MEDICAL_MGIII_PG2_LOWER_LIMIT: 2,
MEDICAL_MGIII_PG2_UPPER_LIMIT: 12,
MEDICAL_MGIII_PG3_LOWER_LIMIT: 12,
MEDICAL_MGIII_PG3_UPPER_LIMIT: 13,
MEDICAL_MGIII_PG4_LOWER_LIMIT: 13,
MEDICAL_MGIII_PG4_UPPER_LIMIT: 18,
MEDICAL_MGIII_PG5_LOWER_LIMIT: 18,
MEDICAL_MGIII_PG5_UPPER_LIMIT: 23,

MEDICAL_AUMG_PG1_LOWER_LIMIT: 0,
MEDICAL_AUMG_PG1_UPPER_LIMIT: 6,
MEDICAL_AUMG_PG2_LOWER_LIMIT: 6,
MEDICAL_AUMG_PG2_UPPER_LIMIT: 11,
MEDICAL_AUMG_PG3_LOWER_LIMIT: 11,
MEDICAL_AUMG_PG3_UPPER_LIMIT: 12,
MEDICAL_AUMG_PG4_LOWER_LIMIT: 12,
MEDICAL_AUMG_PG4_UPPER_LIMIT: 16,
MEDICAL_AUMG_PG5_LOWER_LIMIT: 16,
MEDICAL_AUMG_PG5_UPPER_LIMIT: 20,
MEDICAL_AUMG_PG6_LOWER_LIMIT: 20,
MEDICAL_AUMG_PG6_UPPER_LIMIT: 23,


// Medical questions config for non CA
MEDICAL_PG1_LOWER_LIMIT: 0,
MEDICAL_PG1_UPPER_LIMIT: 2,
MEDICAL_PG2_LOWER_LIMIT: 2,
MEDICAL_PG2_UPPER_LIMIT: 10,
MEDICAL_PG3_LOWER_LIMIT: 10,
MEDICAL_PG3_UPPER_LIMIT: 11,
MEDICAL_PG4_LOWER_LIMIT: 11,
MEDICAL_PG4_UPPER_LIMIT: 16,

// Medical questions config for CA
CA_MEDICAL_PG1_LOWER_LIMIT: 0,
CA_MEDICAL_PG1_UPPER_LIMIT: 3,
CA_MEDICAL_PG2_LOWER_LIMIT: 3,
CA_MEDICAL_PG2_UPPER_LIMIT: 4,
CA_MEDICAL_PG3_LOWER_LIMIT: 4,
CA_MEDICAL_PG3_UPPER_LIMIT: 9,

// Medical Part III questions config
MEDICAL_PART3_PG1_LOWER_LIMIT: 0,
MEDICAL_PART3_PG1_UPPER_LIMIT: 5,
MEDICAL_PART3_PG2_LOWER_LIMIT: 5,
MEDICAL_PART3_PG2_UPPER_LIMIT: 6,
MEDICAL_PART3_PG3_LOWER_LIMIT: 6,
MEDICAL_PART3_PG3_UPPER_LIMIT: 7,
MEDICAL_PART3_PG4_LOWER_LIMIT: 7,
MEDICAL_PART3_PG4_UPPER_LIMIT: 8,

// Physician Grid StateList
STATE_LIST: [
    { value: 'AK', label: 'AK' },
    { value: 'AL', label: 'AL' },
    { value: 'AR', label: 'AR' },
    { value: 'AZ', label: 'AZ' },
    { value: 'CA', label: 'CA' },
    { value: 'CO', label: 'CO' },
    { value: 'CT', label: 'CT' },
    { value: 'DC', label: 'DC' },
    { value: 'DE', label: 'DE' },
    { value: 'FL', label: 'FL' },
    { value: 'GA', label: 'GA' },
    { value: 'GU', label: 'GU' },
    { value: 'HI', label: 'HI' },
    { value: 'IA', label: 'IA' },
    { value: 'ID', label: 'ID' },
    { value: 'IL', label: 'IL' },
    { value: 'IN', label: 'IN' },
    { value: 'KS', label: 'KS' },
    { value: 'KY', label: 'KY' },
    { value: 'LA', label: 'LA' },
    { value: 'MA', label: 'MA' },
    { value: 'MD', label: 'MD' },
    { value: 'ME', label: 'ME' },
    { value: 'MI', label: 'MI' },
    { value: 'MN', label: 'MN' },
    { value: 'MO', label: 'MO' },
    { value: 'MS', label: 'MS' },
    { value: 'MT', label: 'MT' },
    { value: 'NC', label: 'NC' },
    { value: 'ND', label: 'ND' },
    { value: 'NE', label: 'NE' },
    { value: 'NH', label: 'NH' },
    { value: 'NJ', label: 'NJ' },
    { value: 'NM', label: 'NM' },
    { value: 'NV', label: 'NV' },
    { value: 'NY', label: 'NY' },
    { value: 'OH', label: 'OH' },
    { value: 'OK', label: 'OK' },
    { value: 'OR', label: 'OR' },
    { value: 'PA', label: 'PA' },
    { value: 'PR', label: 'PR' },
    { value: 'RI', label: 'RI' },
    { value: 'SC', label: 'SC' },
    { value: 'SD', label: 'SD' },
    { value: 'TN', label: 'TN' },
    { value: 'TX', label: 'TX' },
    { value: 'UT', label: 'UT' },
    { value: 'VA', label: 'VA' },
    { value: 'VI', label: 'VI' },
    { value: 'VT', label: 'VT' },
    { value: 'WA', label: 'WA' },
    { value: 'WI', label: 'WI' },
    { value: 'WV', label: 'WV' },
    { value: 'WY', label: 'WY' }
],

CAPTCHA_SWITCH: true,

DOCUSIGN_CALL_INTERVAL: 10000,
DOCUSIGN_CALL_THRESHOLD: 500,

MOCK_SESSION_DATA: [
    {

    },
    {
        'clientCaseId': '0000S91807',
        'lastFourDigitSsn': '7459',
        'lastName': 'ChandraC' ,
        'zipCode': '10002'
    }
],
MOCK_USER_SESSION_DATA: {
        'clientCaseId': '0000S91807',
        'lastFourDigitSsn': '7459',
        'lastName': 'ChandraC' ,
        'zipCode': '10002'
    },

MOCK_CONTRACT_STATE: 'NJ',

HeaderConstants : {
        APM_ID: '7d287635dba46f849c8a5ca8dc9619b7',
        HEADER_KEYS: {
            X_APPLICATION_NAME: `MG-eInterview`,
            CALLER: `MG-eInterview`,
            CLIENT: `LIFE`,
            CHANNEL: `WEB`,
            ACCESS_CONTROL_ALLOW_ORIGIN: '*'
        },
    },
    GOOGLEV3CAPTCHA : {
        SITE_KEY: '6LcnRBEiAAAAAFdWRS6bVdECWE7_VqmR2fyT2uYp',
        PROD_SITE_KEY: '6Lf_RBEiAAAAAMgaG2YWzqLCyCpb7zZg2YHCLTR8',
        RECAPTCHA_ID: 'googleCaptcha',
        RECAPTCHA_RENDER_URL: 'https://www.google.com/recaptcha/api.js?render='
    }
};
