import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { EntLoaderModule } from '@ng/ent-components/ent-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SessionTimeoutModule } from './life-client/session-timeout/session-timeout.module';
import { SharedModule } from './shared/shared.module';
import { environmentconfig } from '../config/env-config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntWindowRefService, EntClipboardService  } from '@ng/ent-utilities';
import { SafePipe } from 'app/pipes/safe.pipe';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    EntLoaderModule,
    // EntUtilitiesModule,
    SessionTimeoutModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {provide: 'ENG_CONFIG', useValue: environmentconfig.getEnvConfig()},
    EntWindowRefService,
    EntClipboardService
  ],
  bootstrap: [ AppComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
