import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorConfig {

  readonly mgErrorMsg = {
    // error messages
    invalid_input_credentials: 'We are unable to verify the information entered. Please try again.',
    login_retry_exceeded: 'We are unable to verify the information entered. Please contact Lincoln Financial Group at: <br />',
    // tslint:disable-next-line:max-line-length
    system_error: 'The application is currently unavailable at this time. We are working to resolve the issue and apologize for any inconvenience this may cause. Please try again at a later time.',
    declined_msg: 'Thank you for your time. You will be contacted to schedule a phone interview in order to complete your application.',
    update_info_success_msg: 'Your advisor has been notified. Please contact your advisor with any additional questions.',
    data_lost_warning_msg: 'Are you sure you want to cancel? All data will be lost.',
    decline_confirmation_msg: 'Are you sure you want to decline electronic transmissions? Access to this application will be closed.',
    ltc_que_not_comp_wrng_msg: 'You have unanswered questions. All questions are required before proceeding with the application.',
    // tslint:disable-next-line:max-line-length
    session_timeout_msg: 'Your session has expired. To resume your application, please re-enter the following information so we can locate your information.',
    // tslint:disable-next-line:max-line-length
    session_timeout_msg_mob: 'Your session has expired. To resume your application, please re-enter the following information so we can locate your information.',
    // tslint:disable-next-line:max-line-length
    invalid_guid_msg: 'Your application must be accessed from the link in the email that was sent to you. If you no longer have the e-mail, please contact <br />',
    // tslint:disable-next-line:max-line-length
    invalid_guid_msg_mob: 'Your application must be accessed from the link in the email that was sent to you. If you no longer have the e-mail, please contact 844-883-8342.',
    invalid_email_url: 'We are sorry but access to your application has expired.',
    invalid_opt_out: 'Your application is closed as our records indicate that you have declined to electronically submit your application.',
    invalid_application_msg: 'Our records indicate you have already completed your application. Thank you for your submission.',
    user_locked_msg: 'We are unable to verify the information entered. Please contact Lincoln Financial Group at: <br />',
    // tslint:disable-next-line:max-line-length
    user_locked_msg_eapp: 'There have been too many unsuccessful attempts to validate your information. Access to this application has been restricted. Please contact your Financial Advisor for assistance.',
    // tslint:disable-next-line:max-line-length
    med_part_ii_que_not_comp_wrng_msg: 'You have unanswered questions. Please review and provide any additional information, or select the \'confirmation\' checkbox below to proceed.',
    // tslint:disable-next-line: max-line-length
    confirmSsnWarning: 'The SSN provided to Lincoln and what you have entered does not match. Please check what you have entered and correct it if needed. The SSN you have entered will be saved.',
    // tslint:disable-next-line: max-line-length
    einterview_cancelled_msg: 'You have requested to cancel your eInterview. If you wish to change your mind please contact your advisor for assistance.',
    // tslint:disable-next-line: max-line-length
    transaction_id_err_msg: 'An error has occurred, you will not be able to access this eInterview. Please contact NBT at estrategySupport@lfg.com.',
    ltc_top_level_err_msg: 'All questions are required unless marked optional. We have highlighted the questions that still need an answer from you below.',
    page_load_err_msg: 'Please complete the required questions in order to proceed.',
    page_load_info_msg: 'All required questions have been completed. Please confirm the answers/correct answers if needed and then proceed to the next page.',
    page_load_success_msg: 'All required questions have been completed. Proceed to the next page.',
    expiredCase: 'Access to your eInterview has expired. Lincoln and your advisor have been notified. Please feel free to contact your advisor or Lincoln will reach out to you to determine how you would like to proceed.',
    regainAccessMsg: 'Access to your Lincoln online application has expired. Please select the Regain Access button below to renew your access and complete your online application.',
    commonOtpErrorMsg: 'We are facing some technical difficulties. Please try again later.'
   };

}
