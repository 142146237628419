import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const appRoutes: Routes = [
  {
    path: 'lifeclient',
    redirectTo: 'lifeclient',
    pathMatch: 'full'
  },
  {
    path: 'lifeclient',
    loadChildren: () => import('./life-client/life-client.module').then(m => m.LifeClientModule)
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes) ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
