import { Injectable } from '@angular/core';
import { AppConstants } from '../constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  siteKey: string;
  window: any;
  captchaV3Token = new BehaviorSubject<boolean>(false);

  constructor() { 
    if(environment.environ === ''){
      this.siteKey = AppConstants.GOOGLEV3CAPTCHA.PROD_SITE_KEY;
    }else {
      this.siteKey = AppConstants.GOOGLEV3CAPTCHA.SITE_KEY;
    }
  }

  generateGoogleV3Captcha(){
    this.loadCaptchaScript();
    this.getCaptchaToken().subscribe((token) => {
      this.setCaptchaV3Token(token);
    });
  }

  /** 
   * This function is used to create and inject the captcha element in the DOM
   */
  loadCaptchaScript(): void {
    
    if(!document.getElementById(AppConstants.GOOGLEV3CAPTCHA.RECAPTCHA_ID)){
      const captchaEle = document.createElement('script');
      captchaEle.id = AppConstants.GOOGLEV3CAPTCHA.RECAPTCHA_ID;
      captchaEle.src = AppConstants.GOOGLEV3CAPTCHA.RECAPTCHA_RENDER_URL + this.siteKey;
      document.body.appendChild(captchaEle);
    }
  }

  /**
   * This function is used to fetch the captcha response
   *  @returns captcha token
   */
  getCaptchaToken(): Observable<string> {
    this.loadCaptchaScript();
    return new Observable((subscriber) => {
      setTimeout(() => {

        if (this.siteKey && window.hasOwnProperty('grecaptcha')) {
          
          (window as any).grecaptcha
            .execute(this.siteKey, { action: 'submit' })
            .then((token: string) => {
              subscriber?.next(token);
            })
            .catch(() => {
              //Commented by RK due to process break
              // subscriber?.error();
            });
        }
      }, 1000);
    });
  }

  /**
   * Once token value is generated, holds it across the application
   */
  getCaptchaV3Token = this.captchaV3Token.asObservable();
  setCaptchaV3Token(token){
    this.captchaV3Token.next(token);
  }

  /**
   * Removes special characters in GUID if any
   * Formats GUID into 8-4-4-4-12 character pattern
   * @returns formatted GUID
   */
  guidFormatter(guid){
    const isSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    if(isSpecialChar.test(guid)){
      guid = guid.replace(/[^a-zA-Z0-9]/g, "");
    }
    guid = guid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/,"$1-$2-$3-$4-$5");
    return guid;
  }

  /**
   * To mask value
   */
  mask(value, lastFewToShow = 4, maskSymbol = '*'){
    return ('' + value).slice(0, -lastFewToShow).replace(/./g, maskSymbol) + ('' + value).slice(-lastFewToShow);
  }

  /**
   * Return value if present, else return '' or null
   */
  isValuePresent(value, emptyValue){
    if(value)
      return value;
    else
      return emptyValue;
  }
}
