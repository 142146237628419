import { Injectable } from '@angular/core';
import { WindowRefService } from '../../shared/services/window-ref.service';
import { DataService } from '../../shared/services/data.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormotivService {

  window: any;
  guid: string;
  caseId: string;
  isEnableFormotiv = environment.config.enableFormotiv;

  constructor(private windowRef: WindowRefService, private dataService: DataService) {
    this.window = windowRef.getWindow();
  }

  setFormotiv() {
    if (this.isEnableFormotiv) {
      setTimeout(() => {
        this.reloadForDynamicForms(true);
      }, 1500);
    }
  }

  reloadForDynamicForms(newSession) {
    if (this.window.formatic) {
      this.guid = this.dataService.getData('guid');
      const userSessionData = this.dataService.getData('userSessionData');
      const formotiv = this.window.formatic;
      if (this.guid) {
        formotiv.client.setPassthroughValue('passthru1', this.guid);
      }
      formotiv.client.setPassthroughValue('passthru2', 'MoneyGuard_eInterview');
      if (userSessionData?.clientCaseId) {
        formotiv.client.setPassthroughValue('passthru3', userSessionData.clientCaseId);
      }
      if (newSession) {
        formotiv.restart(true);
      } else {
        formotiv.restart();
      }
    }
  }

  pageSubmit(inputThis, tab) {
    if (this.window.formatic && this.isEnableFormotiv) {
      this.checkFormotiv(inputThis, tab);
    }
  }

  checkFormotiv(inputThis, tab) {
    const self = inputThis;
    let pageNum;
    if (self.enableStep === 1) {
      pageNum = 1;
    } else if (self.enableStep === 2) {
      pageNum = 1;
    } else if (self.enableStep === 3) {
      pageNum = 2;
    } else if (self.enableStep === 4) {
      pageNum = 3;
    } else if (self.enableStep === 5) {
      pageNum = 4;
    } else if (self.enableStep === 6) {
      pageNum = 5;
    } else if (self.enableStep === 7) {
      pageNum = 6;
    } 
    
    // else if (self.enableStep === 5) {
    //   pageNum = 5;
    // }

    let formName = '';
    if (tab === 'ltc') {
      formName = 'ltcPage' + pageNum;
    } else if (tab === 'appPart2') {
      formName = 'appPart2Page' + pageNum;
    } else if (tab === 'appPart3') {
      formName = 'appPart3Page' + pageNum;
    }

    const domObj = self.document.getElementById(formName);
    if (formName === 'appPart2Page1' && !this.dataService.getData('isAUMG')) {
      this.triggerPageSubmit(self.document.getElementById('physianGridForm'));
    }

    const contractState = this.dataService.getData('contractState');
    this.isFormNull(contractState, formName);
    this.triggerPageSubmit(domObj);
  }

  isFormNull(contractState, formName){
    if ((contractState !== 'CA' && formName === 'appPart2Page4') || (contractState === 'CA' && formName === 'appPart2Page3')) {
      const form = self.document.getElementById('medicationGridForm');
      if (form != null && form !== undefined) {
        this.triggerPageSubmit(form);
      }
    }
  }

  triggerPageSubmit(domObj) {
    if (this.window.formatic && this.isEnableFormotiv) {
      const formotiv = this.window.formatic;
      formotiv.client.triggerSubmit(domObj);
    }
  }

  triggerMultipageComplete() {
    if (this.window.formatic && this.isEnableFormotiv) {
      const formotiv = this.window.formatic;
      formotiv.client.triggerMultipageComplete();
    }
  }

  triggerChange(inputThis, id, nameOfField, formName?) {
    if (this.window.formatic && this.isEnableFormotiv) {
    //  inputThis; Sonar Bug
      const formotiv = this.window.formatic;
      formotiv.client.triggerChangedCustom(nameOfField + '_' + id, nameOfField + '_' + id, 'string', formName);
    }
  }

  
  triggerChangeCustom(
    type: string,
    id: string,
    nameOfField: string,
    formName?: string,
    data?: any
  ) {
    if (this.isEnableFormotiv) {
      const elId = nameOfField + '_' + id;
      if (this.window.formatic) {
        this.isWindowFormatic(elId, type, id, nameOfField, formName, data);
      }
    }
  }

  isWindowFormatic(elId, type, id, nameOfField, formName, data) {
    
    const formotiv = this.window.formatic;
    let frmCntl = document.getElementById(elId);
    if (!frmCntl) {
      frmCntl = document.getElementById(id);
    }
    elId = frmCntl ? frmCntl.id : null;
    if(frmCntl?.closest('form')){
      formName = frmCntl.closest('form').getAttribute('id');
    }else{
      formName = null;
    }

    let friendlyName;
    if(data?.fieldId){
      friendlyName = data.fieldId;
    }else{
      friendlyName = null;
    }
    formotiv.client.triggerChangedCustom(
      elId,
      type === 'dropdown' && friendlyName ? friendlyName : nameOfField,
      type,
      formName
    );
  }

}
