const host = `${window.location.hostname}`;
let environ : string;
if(host === 'mgeinterview-dev.web.lfg.com' || host === 'localhost'){
  environ  = '-dev';
}
else if(host === 'mgeinterview-test.web.lfg.com'){
  environ = '-test';
}
//PROD
else{
  environ = '';
}

export const environment = {
  host:host,
  environ: environ,
  production: true,
  basePath: {
    dev: 'https://api.mgeinterview-dev.web.lfg.com',
    test: 'https://api.mgeinterview-test.web.lfg.com',
    preprod: 'https://api.mgeinterview-test.web.lfg.com',
    prod: 'https://api.mgeinterview.web.lfg.com',
    uat: 'https://api.mgeinterview-dev.web.lfg.com'
  },
  hoPath: {
    dev: "https://ui-mg.einterview-ext-dev.aws.cloud.lfg.com",
    test: 'https://ui-mg.einterview-ext-test.aws.cloud.lfg.com',
    preprod: 'https://ui-mg.einterview-ext-test.aws.cloud.lfg.com',
    prod: 'https://ui-mg.einterview.aws.cloud.lfg.com',
    uat: 'https://ui-mg.einterview-ext-dev.aws.cloud.lfg.com'
  },
  envName: 'Production',
  serviceUrls: {
    keepAlive: 'assets/images/ajax-loader.gif'
  },
  config: {
    enableFormotiv: true,
    enableFormotivAIS: true
  },
  validateGuid: '/mgeinterview-client/getLoginStatus',
  checkSSNUrl: '/mgeinterview-client/checkSSN',
  saveLTCAnswers: '/mgeinterview-client/saveLtcAnswers',
  saveMedicalAnswers: '/mgeinterview-client/saveMedicalAnswers',
  saveMedicalPart3Answers: '/mgeinterview-client/saveMedicalAnswers',
  regainCaseStatus: '/mgeinterview-client/regainCaseStatus',
  optOut: '/mgeinterview-client/updateOptOutIndicator',
  updateInfo: '/mgeinterview-client/clientInfoUpdate',
  getLTCQuestions: '/mgeinterview-client/getQuestions',
  getMedicalQuestions: '/mgeinterview-client/getQuestions',
  getMedicalPart3Questions: '/mgeinterview-client/getQuestions',
  getNBITResponseUrl: '/mgeinterview-client/submitAnswersToNBIT',
  loginAuthorization: '/mgeinterview-client/getClientDetails',
  contactAttemptLogUrl: '/mgeinterview-client/updateStatusToNBIT',
  updateToAisNbitUrl: '/mgeinterview-client/updateAIS',
  if02AisNotifyUrl: '/mgeinterview-client/updateAISCompleteStatus',
  eInterviewCompleteStatus: '/mgeinterview-client/updateInterviewCompleteStatus',
  getDocuSignUrl: '/mgeinterview-client/getDocuSignURL',
  getDocuSignURLOnLogin: '/mgeinterview-client/getDocuSignURLOnLogin',
  
  getLineType: '/mgeinterview-client/getLineType',
  checkUserAvailability: '/mgeinterview-client/checkUserAvailability',
  sendTextOtp: '/mgeinterview-client/sendTextOtp',
  sendVoiceCallOtp: '/mgeinterview-client/sendVoicecall',
  validateOtp: '/mgeinterview-client/validateOtp',
  registerPhoneNumber: '/mgeinterview-client/registerPhoneNumber',

  getcasedata: '/mgeinterview-client/getcasedata',
  canceldisclosure: '/mgeinterview-client/canceldisclosure',
  sendCancelDisclosureEmail: '/mgeinterview-client/sendCancelDisclosureEmail',
  disclosuresearch: '/mgeinterview-client/disclosuresearch',
  disclosures: '/mgeinterview-client/disclosures',
  disclosurephysicians: '/mgeinterview-client/disclosurephysicians',
  disclosurewithoutphysician: '/mgeinterview-client/disclosurewithoutphysician',
  submitdisclosurephysician: '/mgeinterview-client/submitdisclosurephysician',
  unlinkdisclosurephysician: '/mgeinterview-client/unlinkdisclosurephysician',
  isentityready: '/mgeinterview-client/isentityready',
  uwCalc:'/mgeinterview-client/uwCalc',
  physicians: '/mgeinterview-client/physicians',
  editphysician: '/mgeinterview-client/editphysician',
  newreflexivequestion: '/mgeinterview-client/newreflexivequestion',
  savebasequestionsresponse: '/mgeinterview-client/savebasequestionsresponse',
  submitdisclosure: '/mgeinterview-client/submitdisclosure',
  reflexivesubmitdisclosure: '/mgeinterview-client/reflexivesubmitdisclosure',
  retrievelist: '/mgeinterview-client/retrievelist',
  submitphysician: '/mgeinterview-client/submitphysician',
  submitnewphysician: '/mgeinterview-client/submitnewphysician',
  updateunknowndisclosure: '/mgeinterview-client/updateunknowndisclosure',
  unknowndisclosuresubmit: '/mgeinterview-client/unknowndisclosuresubmit',
  updateDefaultLandingPage: '/mgeinterview-client/updateDefaultLandingPage',
  findWordMeaning: '/mgeinterview-client/findWordMeaning',
  
  sessionTimeout: {
    DURATION_IN_MIN: 39,
    SHOW_ALERT_BEFORE_IN_MIN: 0,
    KEEP_ALIVE_REQUEST_THRESHOLD_IN_MIN: 2
  },

  scriptUrls: {
    // utag dev url
    // utagUrl: "//tags.tiqcdn.com/utag/lfg/lfgrwd/dev/utag.js"
    // utag prod url
    utagUrl: '//tags.tiqcdn.com/utag/lfg/lfgrwd/prod/utag.js'
  },

  awsClickToChat: true,
  clickToChatURL: {
    PUBLIC_CHAT_SERVICE_URL: '/pbl-gateway-service-app/servlet/pbl-gateway/gatewayServlet'
  },
  awsclickToChatURL: {
    dev: 'https://cc-chat-dev.web.lfg.com/cc-ui-pbl-chat/?',
    test: 'https://cc-chat-preprod.web.lfg.com/cc-ui-pbl-chat/?',
    prod: 'https://chat.lincolnfinancial.com/cc-ui-pbl-chat/?'
  }
  
};
