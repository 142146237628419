<header class="topheader">
    <!-- Site Navigation -->
    <nav role="navigation" class="site-navigation" [ngClass]="{ 'site-navigation--center-logo': navData.headerType }">
      <div class="topheader-logo-cont">
        <a class="topheader-logo no-global-analytics" href="https://www.lfg.com">
        <img class="u-image-responsive" src="{{ navData.logoImgSrc }}"
          alt="Lincoln Financial Group Logo (click to navigate to home page)" />
          </a>
      </div>
    </nav>
  </header>
  