import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionTimeoutComponent } from './session-timeout.component';
import { WindowRefService } from '../../shared/services/window-ref.service';

import { EntRestClientService } from '@ng/ent-utilities';


import { SessionTimeoutService } from './session-timeout.service';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SessionTimeoutComponent],
  exports: [
    CommonModule,
    SessionTimeoutComponent
 ],
 providers: [WindowRefService, EntRestClientService, SessionTimeoutService],
 schemas: [ CUSTOM_ELEMENTS_SCHEMA]
})
export class SessionTimeoutModule { }
