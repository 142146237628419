import { environment } from '../environments/environment';

export const environmentconfig = {
  
  getEnvConfig: () => {
    const url = window.location.hostname;
    let envFlag : boolean;
    let envUrl= environment.basePath.prod;

    if (url.includes('-dev'))
      envUrl= environment.basePath.dev;
    else if (url.includes('-test'))
      envUrl= environment.basePath.test;
    else if (url.includes('-uat'))
      envUrl= environment.basePath.uat;
    else if (url.includes('-preprod'))
      envUrl=  environment.basePath.preprod;
    else if (url.includes('localhost'))
      envUrl= '//'+window.location.host;
    else if (url.startsWith('lincolnfinancial.com'))
      envUrl= environment.basePath.prod;
    
    if (url.indexOf('localhost') > -1) {
      envFlag = true;
    } else {
      envFlag = false;
    }
    
    return { useMock: envFlag, apiUrl: envUrl };
  }

};
