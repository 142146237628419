import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ChatFormModel } from '../model/chat-form-model';
import { WindowRefService } from '../services/window-ref.service';
import { ConfigurationService } from '../analytics/configuration.service';
import { environment } from '../../../environments/environment';
import { ErrorConfig } from '../../../config/error-config';
import { CommonService as mgCommonService } from 'app/shared/services/common.service';;
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../constants';
import { Observable } from 'rxjs';
import { AnalyticsService } from '../analytics/analytics.service';
import { FormotivService } from './formotiv.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  chatWindow;
  ltcMedicationReadOnly = new BehaviorSubject<boolean>(false);
  ltcDisplayMedSubQ = new BehaviorSubject<boolean>(false);
  token: any;
  constructor(private nativeWindow: WindowRefService, 
    public config: ConfigurationService, 
    public errConfig: ErrorConfig,
    public httpClient: HttpClient,
    private _commonService: mgCommonService,
    private analyticsService: AnalyticsService,
    private formotivService: FormotivService) {}

  openHeaderFooterLinksInNewTab(inputThis) {
    const self = inputThis;
    self.document.links[0]?.setAttribute('target', '_blank');
    self.document.links[0]?.setAttribute('href', 'https://www.lfg.com');
    const linksCount = self.document.links.length;
    self.document.links[linksCount - 1]?.setAttribute('target', '_blank');
    self.document.links[linksCount - 2]?.setAttribute('target', '_blank');
  }

  enableReviewCheckBox(inputThis, event) {
    const self = inputThis;
    let enableSubmit = false;
    if (event.target.checked) {
      enableSubmit = true;
    }
    self._subjectService.setCheckBoxValue(enableSubmit);
  }



  displaySections(inputThis, stepNo) {
    const self = inputThis;
    const el = self._elementRef.nativeElement;
    const sections = el.querySelectorAll('.step-content');
    if (sections) {
      for (const k in sections) {
        if (k) {
          if (k === 'length') { return false; }
          const element = sections[k];
          const elStep = parseInt(k, 10) + 1;
          if (elStep === stepNo) {
            element.classList.add('enable-section');
            element.classList.remove('disable-section');
            self.enableStep = stepNo;
            self.enableNext = true;
            window.scrollTo(0, 0);
          } else {

            element.classList.add('enable-section');
            element.classList.remove('disable-section');
          }
        }
      }
    }
  }

  changeLTCMedReadOnly(isReadOnly: boolean){
    this.ltcMedicationReadOnly.next(isReadOnly);
  }

  changeltcDisplayMedSubQ(displayMedSubQ: boolean){
    this.ltcDisplayMedSubQ.next(displayMedSubQ);
  }

  mapQuestionAndAnswersPart2(inputThis) {
    const self = inputThis;
    for (let m = 0; m < self.arrQuesLList.length; m++) {
      self.medAns = self.arrQuesLList[m];
      this.iterateQuestions(self, m);
    }
    self.checkQuestAnsweredStatus();
  }

  iterateQuestions(self, m) {
    
    for (const element of self.questions) {
      if (element.hasSubQuestions && element.hasAnswerOption === 'N') {
        this.checkAnswerOption(element, 'N', self);
      } else if (element.hasSubQuestions && element.hasAnswerOption === 'Y') {
        this.checkAnswerOption(element, 'Y', self);
      } else {
        if (self.medAns[element.questionKey] === 'Yes') {
          element.answer = 'Yes';
        } else if (self.medAns[element.questionKey] === 'No') {
          element.answer = 'No';
        }
        if (m == 0 && element.questionKey === 'MGAU_Q_DailyActivities_3') {
          element.answer = self.medAns[element.questionKey];
        }
      }
    }
  }

  checkAnswerOption(element, option, self){
    if(option === 'N'){
      for (const j of element.subQuestions) {
        if (self.medAns[j.questionKey] === 'Yes') {
          j.answer = 'Yes';
        } else if (self.medAns[j.questionKey] === 'No') {
          j.answer = 'No';
        }
      }
    }
    if(option === 'Y'){
      if (self.medAns[element.questionKey] !== undefined) {
        element.answer = self.medAns[element.questionKey];
      }
      this.iterateAnsOptionY(element, self);
    }
  }

  iterateAnsOptionY(element, self){
    for (const j of element.subQuestions) {
      const ans = self.dataService.getData('alcoholSubQuest');
      if (ans !== null && ans !== undefined) {
        j.answer = ans[j.questionKey];
      }
    }
  }

  mapQuestionAndAnswersPart3(inputThis) {
    const self = inputThis;
    for (const element of self.arrQuesLList) {
      self.medPart3Ans = element;
      for (let i = 0; i < self.questions.length; i++) {
        this.checkEachQuestion(self, i);
        
      }
    }
    self.checkQuestAnsweredStatus();
  }

  checkEachQuestion(self, i) {
    
    if (self.questions[i].hasSubQuestions) {
      
      for (const element of self.questions[i].subQuestions) {
        if (self.medPart3Ans[element.questionKey] === 'Yes') {
          element.answer = 'Yes';
        } else if (self.medPart3Ans[element.questionKey] === 'No') {
          element.answer = 'No';
        }
      }
    } else {
      if (self.medPart3Ans[self.questions[i].questionKey] === 'Yes') {
        self.questions[i].answer = 'Yes';
      }
      if (self.medPart3Ans[self.questions[i].questionKey] === 'No') {
        self.questions[i].answer = 'No';
      }
    }
  }

  setBackendError(inputThis) {
    const self = inputThis;
    setTimeout(() => {
      window.scrollTo(0, 0);
      self.backendError = true;
      self.success = false;
      self.lfgLoaderService.hide();
    }, 500);
  }

  getKeyTyped(inputThis, event, htmlElem) {
    const self = inputThis;
    self.currentCursorPosition = htmlElem.selectionStart;
    if (htmlElem.selectionStart !== htmlElem.selectionEnd) {
      self.textHighlighted = true;
      self.cursorPositionAtHighlightEnd = htmlElem.selectionEnd;
    } else {
      self.textHighlighted = false;
    }
    if (event.keyCode === 8 || event.keyCode === 190 || event.keyCode === 46) {
      if (event.keyCode === 8 || event.keyCode === 46) {
        this.checkKeyCode(self, event);
      } else {
        self.replaceSSNFlag = true;
      }
      // checking if dot key pressed
      if (event.keyCode === 190) {
        self.pointKeyPressed = true;
      } else {
        self.pointKeyPressed = false;
      }
    } else {
      self.replaceSSNFlag = true;
      self.pointKeyPressed = false;
      self.deletePreseed = false;
    }
  }

  checkKeyCode(self, event) {
    self.replaceSSNFlag = false;
    if (event.keyCode === 46) {   // checking if delete key pressed
      self.deletePreseed = true;
    } else {
      self.deletePreseed = false;
    }
    if (event.keyCode === 8) {  // checking if backspace key pressed
      self.backspacePressed = true;
    } else {
      self.backspacePressed = false;
    }
  }

  maskSSN(inputThis, inputValue, SSNElement) {
    const self = inputThis;
    // fix for ios
    let fieldlength = 4;
    if (navigator.userAgent.match('iPhone')) { fieldlength = 3; }
    if (inputValue.length === 4 && !self.lastdigit && (!self.deletePreseed || !self.backspacePressed)) { return false; }
    // end

    if ((inputValue.length <= fieldlength) || (inputValue.length <= 4 && navigator.userAgent.match('iPhone'))) {
      const c = '\u2022';
      this.checkInputLength(inputValue, self);
      if (!self.pointKeyPressed && !self.deletePreseed) {
        this.checkReplaceSSNFlag(inputValue, self, SSNElement, c);
      } else if (self.deletePreseed && !self.pointKeyPressed) {
        this.checkDeleteKeyPress(inputValue, self, SSNElement);
      } else {
        this.checkIsSSNPresent(inputValue, self);
      }
    }
  }

  checkInputLength(inputValue, self){
    if (inputValue.length === 4) {
      self.lastdigit = false;
    } else {
      self.lastdigit = true;
    }
  }

  checkReplaceSSNFlag(inputValue, self, SSNElement, c){
    if (self.replaceSSNFlag) {
      this.checkInputVal(inputValue, self, SSNElement, c);
    } else {
      // handling backspace key press: removing element from input on every backspace key press
      this.checkBackspaceKeyPress(inputValue, self, SSNElement);
    }
  }

  checkInputVal(inputValue, self, SSNElement, c){
    if (inputValue !== '') {
      if (self.SSNPresent) {
        this.checkTextHighlight(self, inputValue, c);
      } else {
        if (SSNElement.value.length === 1) {
          self.setSSNValue = inputValue;
        } else {
          if (self.textHighlighted) {
            self.setSSNValue = inputValue;
          } else {
            self.setSSNValue = self.setSSNValue + inputValue[inputValue.length - 1];
          }
        }
      }

      if (self.setSSNValue.length >= 2) {
        this.checkSSNvalue(self, c);
      }
    }
  }

  checkTextHighlight(self, inputValue, c) {
    self.SSNPresent = false;
    if (self.textHighlighted) {
      self.setSSNValue = inputValue;
      if (self.setSSNValue.indexOf(c) !== -1) {
        for (let i = 0; i < self.setSSNValue.length; i++) {
          
          if (self.setSSNValue[i] === c) {
            self.setSSNValue = self.setSSNValue.replace(self.setSSNValue[i], self.previousSSN[i]);
          }
        }
      }
    } else {
      self.setSSNValue = self.previousSSN + inputValue[inputValue.length - 1];
    }
  }

  checkSSNvalue(self, c) {
    self.replacedArray = [];
    self.setSSNValue.split('');
    const maskTillIndex = self.setSSNValue.length - 2;

    for (let i = 0; i <= maskTillIndex; i++) {
      self.replacedArray[i] = c;
    }

    if (self.setSSNValue.length === 4) {
      self.replacedArray[self.replacedArray.length] = c;
    } else {
      self.replacedArray[self.replacedArray.length] = self.setSSNValue[self.setSSNValue.length - 1];
    }
    self.unmaskedValue = self.setSSNValue;
    self.maskedSSNString = self.replacedArray.join('');
    (self.loginForm.controls['last4digitssn']).setValue(self.maskedSSNString);
  }

  checkBackspaceKeyPress(inputValue, self, SSNElement){
    if (self.backspacePressed) {
      if ((inputValue.length === 3) && (self.replacedArray.length === 0) && self.SSNPresent) {
        self.replacedArray = inputValue.split('');
        self.SSNPresent = false;
      }
      if (self.textHighlighted) {
        if (self.SSNPresent) {
          self.SSNPresent = false;
        }
        const numElemToDelete = self.cursorPositionAtHighlightEnd - self.currentCursorPosition;
        self.replacedArray.splice(self.currentCursorPosition, numElemToDelete);
        // tslint:disable-next-line:max-line-length
        self.setSSNValue = self.setSSNValue.replace(self.setSSNValue.substring(self.currentCursorPosition, self.cursorPositionAtHighlightEnd), '');
      } else {
        self.replacedArray.splice((self.currentCursorPosition - 1), 1);
        self.setSSNValue = self.setSSNValue.replace(self.setSSNValue[(self.currentCursorPosition - 1)], '');
      }
      self.unmaskedValue = self.setSSNValue;
    }
    self.maskedSSNString = self.replacedArray.join('');
    (self.loginForm.controls['last4digitssn']).setValue(self.maskedSSNString, { onlySelf: true });
    SSNElement.setSelectionRange(self.currentCursorPosition - 1, self.currentCursorPosition - 1, null);
  }

  checkDeleteKeyPress(inputValue, self, SSNElement){
    if (self.deletePreseed) {
     
      if ((inputValue.length === 3) && (self.replacedArray.length === 0) && self.SSNPresent) {
        self.replacedArray = inputValue.split('');
        self.SSNPresent = false;
      }
      if (self.textHighlighted) {
        if (self.SSNPresent) {
          self.SSNPresent = false;
        }
        const numElemToDelete = self.cursorPositionAtHighlightEnd - self.currentCursorPosition;
        self.replacedArray.splice(self.currentCursorPosition, numElemToDelete);
        // tslint:disable-next-line:max-line-length
        self.setSSNValue = self.setSSNValue.replace(self.setSSNValue.substring(self.currentCursorPosition, self.cursorPositionAtHighlightEnd), '');
      } else {
        self.replacedArray.splice((self.currentCursorPosition), 1);
        self.setSSNValue = self.setSSNValue.replace(self.setSSNValue[(self.currentCursorPosition)], '');
      }
      self.unmaskedValue = self.setSSNValue;
    }
    self.maskedSSNString = self.replacedArray.join('');
    (self.loginForm.controls['last4digitssn']).setValue(self.maskedSSNString, { onlySelf: true });
    SSNElement.setSelectionRange(self.currentCursorPosition, self.currentCursorPosition, null);
  }

  checkIsSSNPresent(inputValue, self) {
    inputValue.replace('.', '');
    if (self.SSNPresent) {
      self.maskedSSNString = self.previousMaskedSSN;
    }
    (self.loginForm.controls['last4digitssn']).setValue(self.maskedSSNString, { onlySelf: true });
    self.loginForm.controls['last4digitssn'].updateValueAndValidity();
  }

  // Click to Chat Service
  launchClickToChat(inputThis): Observable<string> {    
    this.formotivService.triggerChangeCustom('link', 'chatlink', 'click2Chat');
    return new Observable((subscriber) => {
      if (this.chatWindow !== undefined && !this.chatWindow.closed) {
        this.chatWindow.focus();
        subscriber.next("OK")
      }
      const self = inputThis;
      const chatModel = this.initChat(self);
      this.checkIsClickedChat(chatModel).subscribe({next:result=>{subscriber.next(result)},error:error=>{
        this.analyticsService.setAnalyticsEventData('error', 'Error Occured in chat launch', 'mg eSubmission','ChatError' + JSON.stringify(error), 'not verified');
        subscriber.error(error)}});
    })
    
  }

  checkIsClickedChat(chatModel): Observable<string>{
    return new Observable((subscriber) => {
      let chatURL = this.getClickToChatURL();
      const cahtAPI = this.getBaseUrl()+"/mgeinterview-client/chat";
      const payload = {
        "firstName": chatModel?.firstName,
        "lastName": chatModel?.lastName,
        "email": chatModel?.email,
        "dateTimeOfCall": new Date().toISOString()
      }
    
      this._commonService.loadCaptchaScript();
      this._commonService.getCaptchaToken().subscribe(token => {
        
        this._commonService.setCaptchaV3Token(token);

        this.clickToAPICall(cahtAPI, payload,{WSUID:chatModel?.WSUID}).subscribe(
          {next: result => {
              if(result.statusCode =='OK' && result.status =='SUCCESS'){
                chatURL+="appId="+chatModel.appId+"&wsuid="+chatModel.WSUID
                this.chatWindow = this.nativeWindow.getWindow().open(chatURL, 'Live Chat',
          'scrollbars=1,toolbar=0,menubar=0,status=0,resizable=1,left=400,width=620,height=550');
                subscriber.next(result.statusCode)
              }
          },
          error: error => {
            this.analyticsService.setAnalyticsEventData('error', 'Chat Error', 'mg eSubmission','ChatError' + JSON.stringify(error), 'not verified');
            subscriber.error("Error")
          }}
        );
      });
    })
    
    
  }

  initChat(self) {
    const firstName = self.dataService.getData('firstName');
    const lastName = self.dataService.getData('lastName');
    const emailAddress = self.dataService.getData('emailAddress');
    const userSessionData = self.dataService.getData('userSessionData');
    const chatModel = new ChatFormModel();
    if (environment.awsClickToChat) {
      chatModel.appId = 'LNB_CHAT_MG_PUBLIC';
    } else {
      chatModel.SERVICE_ID = 'PUBLIC_CHAT_SERVICE';
      chatModel.actvtySrcName = 'LFGMGCTC';
      chatModel.appId = '700';
      chatModel.lob = 'MoneyGuard';
      chatModel.pageId = '700';
    }
    if (firstName !== undefined && firstName !== null) {
      chatModel.firstName = firstName;
    }
    if (lastName !== undefined && lastName !== null) {
      chatModel.lastName = lastName;
    }
    if (emailAddress !== undefined && emailAddress !== null) {
      if (environment.awsClickToChat) {
        chatModel.email = emailAddress;
      } else {
        chatModel.emailId = emailAddress;
      }
    }
    if (userSessionData !== undefined && userSessionData !== null) {
      chatModel.policyNumber = userSessionData.clientCaseId;
    }
    chatModel.WSUID = self.dataService.getData('guid');
    return chatModel;
  }

  getClickToChatURL() {
    const url = window.location.hostname;
    const env: string = this.getEnvironmentName().toLowerCase();
    let c2curl = environment.awsclickToChatURL.dev;
    if (url.indexOf('wcs-ca.lfg.com') !== -1) {
      c2curl = environment.awsclickToChatURL.test;
    } else if (url.indexOf('wcs-rss-preprod.lfg.com') !== -1) {
      c2curl = environment.awsclickToChatURL.test;
    } else if (url.indexOf('www.lfg.com') !== -1) {
      c2curl = environment.awsclickToChatURL.prod;
    } else if (url.indexOf('localhost') !== -1) {
      c2curl = environment.awsclickToChatURL.dev;
    }else if(url.indexOf('dev') !== -1){
      c2curl = environment.awsclickToChatURL.dev;
    }else if(url.indexOf('test') !== -1){
      c2curl = environment.awsclickToChatURL.test;
    }else if(env==='prod'){
      c2curl = environment.awsclickToChatURL.prod;
    }
    return c2curl;
  }
 

  getSystemError(inputThis) {
    let msg = this.errConfig.mgErrorMsg.system_error;
    const self = inputThis;
    const customerServiceDetails = self.dataService.getData('customerServiceDetails');
    if (customerServiceDetails) {
      msg = this.errConfig.mgErrorMsg.system_error + ' or call ' + customerServiceDetails.cserviceNumber 
            // + ' ' + customerServiceDetails.cserviceNumberOptions 
            + ' during our hours of operation, ';
      msg += `<span aria-hidden="true">${customerServiceDetails.cserviceHoursOfOperationUI}</span>.
      <span class="screen-reader-only">${customerServiceDetails.cserviceHoursOfOperationADA}</span>`;
    }
    return msg;
  }

  /** return environment variable */
  getEnvironmentName() {
    const host = window.location.host;
    if (host.includes('-dev'))
      return 'DEV';
    else if (host.includes('-test'))
      return 'TEST';
    else if (host.includes('-uat'))
      return 'UAT';
    else if (host.includes('-preprod'))
      return 'PREPROD';
    else if (host.includes('localhost'))
      return 'localhost'
    else if (host.startsWith('lincolnfinancial.com'))
      return 'PROD';
    else 
      return 'PROD';
  }

  /** returning the base URL to make API calls */
  getBaseUrl() {
    let env: string = this.getEnvironmentName().toLowerCase();
    const protocal = 'http://';
    if (env === 'prod')
      return environment.basePath.prod;
    else if (env === 'localhost')
      return protocal + window.location.host;
    else if (env === 'dev' || env === 'uat')
      return environment.basePath.dev;
    else if (env === 'test')
      return environment.basePath.test;
    else if (env === 'preprod')
      return environment.basePath.preprod;
  }
  
  /** returning the ho URL to make API calls */
  getHoUrl() {
    let env: string = this.getEnvironmentName().toLowerCase();
    const protocal = 'http://';
    if (env === 'prod')
      return environment.hoPath.prod;
    else if (env === 'localhost')
    return protocal + window.location.host;
    else if (env === 'dev' || env === 'uat')
      return environment.hoPath.dev;
    else if (env === 'test')
      return environment.hoPath.test;
    else if (env === 'preprod')
      return environment.hoPath.preprod;
      else if (env === 'uat')
      return environment.hoPath.uat;
  }

  clickToAPICall(url: string, requestData?: any, header?): Observable<any> {
   
    const env : string = this.getEnvironmentName().toLowerCase();
    if(env==='localhost1'){
      return this.httpClient.get('assets/mock/getChatToClickMock.json');
    }else{
      this._commonService.getCaptchaV3Token.subscribe(token => this.token = token);
      return this.httpClient.post(url, requestData, {headers: {
        'Content-Type':  'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': AppConstants.HeaderConstants.HEADER_KEYS.ACCESS_CONTROL_ALLOW_ORIGIN,
        'APM_ID': AppConstants.HeaderConstants.APM_ID,
        'AWS_ENV': env,
        'CALLER': AppConstants.HeaderConstants.HEADER_KEYS.CALLER,
        'CHANNEL': AppConstants.HeaderConstants.HEADER_KEYS.CHANNEL,
        'CLIENT': AppConstants.HeaderConstants.HEADER_KEYS.CLIENT,
        'X-application-name': AppConstants.HeaderConstants.HEADER_KEYS.X_APPLICATION_NAME,
        'wsuid': header.WSUID 
      }});
    }
    
  }
}
